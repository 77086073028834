<template>
    <section>
      <div class="w-full rounded-lg shadow-md p-4">
        <div class="w-full border-b-2 border-blue-600 ">
          <p class="text-blue-600 font-medium text-md">
            Datos academicos
          </p>
        </div>
        <div v-for="(estudio, i) in documentos" :key="i" class="w-full flex py-4 items-center border-b">
          <div class="w-5/12">
            <label class="text-gray-600 text-xs">Nivel de estudios:</label>
            <p class="font-bold mt-1">
              {{estudio.level}}
            </p>
          </div>
          <div class="w-5/12">
            <label class="text-gray-600 text-xs">Titulo:</label>
            <p class="font-bold mt-1">
              {{estudio.title}}
            </p>
          </div>
          <div>
            <a v-if="estudio.path" :href="env + estudio.path" target="_blank" rel="noopener noreferrer">
                <Button class="bg-blue-600 text-white" icon="pi pi-eye" />
            </a>
            <a v-else :href="env + estudio.path" target="_blank" rel="noopener noreferrer">
                <Button class="bg-blue-600 text-white" icon="pi pi-file" />
            </a>
          </div>
        </div>
      </div>
    </section>
  </template>
  <script>
  import empleadosStore from '../../../../../stores/empleados.store'
  import { computed } from 'vue'
  export default {
    name: 'ComponentAcademyData',
    setup () {
      const documentos = computed(() => empleadosStore.getters._documentos)
      // Data
      const env = process.env.VUE_APP_MICROSERVICE_API_USUARIOS.split('/api')[0]

      return {
        env,
        documentos
      }
    }
  }
  </script>

  <style lang="scss" scoped>
  ::v-deep(.p-dropdown-trigger) {
    display: none;

  }
  ::v-deep(.p-button.p-component.p-button-icon-only.p-datepicker-trigger) {
    background-color: rgba(113, 128, 150, var(--tw-text-opacity)) !important;
    border-color: rgba(113, 128, 150, var(--tw-text-opacity)) !important;
}
  </style>
